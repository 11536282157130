import ArberShabani from "../src/images/team/arbershabani.jpeg";
import EdonaRexhepi from "../src/images/team/edonarexhepi.jpeg";


export const teamMembers = [
  {
    fullName: 'Arbër Shabani',
    // position: 'CEO',
    position: 'Senior Software Engineer & Chief Executive Officer',
    photo: ArberShabani,
    url: '/cv/arber-shabani',
    description: `
    A highly motivated and skilled Software Engineer, bringing a wealth of experience across multiple industries, specializing in JS technologies. Whether it's fintech, healthcare, logistics, e-commerce, or aviation, he's passionate about delivering high-quality work and being efficient. On a personal level, enjoys pursuing a variety of hobbies, including flying helicopters, freediving, and playing piano.
    `
  },
  {
    fullName: 'Edona Rexhepi',
    position: 'Chief Marketing Officer',
    photo: EdonaRexhepi,
    // url: '/cv/edona-rexhepi',
    description: `
    Experienced Chief Marketing Officer adept at achieving remarkable outcomes. Strategic visionary and influential leader, focused on enhancing marketing efforts, expanding brand reach, and attracting new customers. Skilled in developing successful marketing strategies, fostering high-performing teams, and utilizing data-driven insights to optimize campaigns and drive maximum returns on investment
    `
  },
  // {
  //   fullName: 'Erëza Krasniqi',
  //   position: 'React & React Native Engineer',
  //   photo: ErezaKrasniqi,
  //   url: '/cv/ereza-krasniqi',
  //   description: `
  //   A goal-oriented, determined, and ambitious individual with a profound passion for coding. Nearing graduation as a Computer Engineer from the Faculty of Electrical and Computer Engineering, all the while embracing new learning opportunities along her journey. With expertise in building dynamic and responsive web and mobile applications using JavaScript and its frameworks, she excels in applying modern UI/UX principles to ensure the delivery of seamless and user-friendly experiences.
  //   `,
  //   // description: `
  //   // React and React Native Developer with expertise in building dynamic and responsive web and mobile applications using JavaScript and its frameworks, employing modern UI/UX principles to deliver seamless user experiences.
  //   // `
  // },
];