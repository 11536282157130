export const labelNames = ['React Developer', 'React Native Developer', 'Web Developer', 'Mobile Developer', 'Web Designer', 'Not sure'];

export const developers = [
  { id: 'react_dev', type: 'radio', name: 'dev', value: 'react_dev' },
  { id: 'react_native_dev', type: 'radio', name: 'dev', value: 'react_native_dev' },
  { id: 'web_dev', type: 'radio', name: 'dev', value: 'web_dev' },
  { id: 'mob_dev', type: 'radio', name: 'dev', value: 'mob_dev' },
  { id: 'web_design', type: 'radio', name: 'dev', value: 'web_design' },
  { id: 'not_sure', type: 'radio', name: 'dev', value: 'not_sure' },
];

export const labelNamesSecond = ['Up to 1 month', '1-3 months', '3-6 months', 'Up to 1 year', 'Not sure'];

export const developersSecond = [
  { id: 'react_dev', type: 'radio', name: 'dev2', value: 'react_dev' },
  { id: 'react_native_dev', type: 'radio', name: 'dev2', value: 'react_native_dev' },
  { id: 'web_dev', type: 'radio', name: 'dev2', value: 'web_dev' },
  { id: 'mob_dev', type: 'radio', name: 'dev2', value: 'mob_dev' },
  { id: 'web_design', type: 'radio', name: 'dev2', value: 'web_design' },
];