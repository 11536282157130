import React from 'react';
import './index.css';
import '../../layouts/index.css';
import { teamMembers } from '../../../assets';
import { Link } from 'gatsby';
// import { ScrollToTop } from '../../hooks/scrollToTop';

const AboutPage = () => {
  return (
    <div className="about-us-container">
      <div className="about-us-image">
        <div className="container">
          <h1 className="about-us-title">
            Creativity
            <br />
            meets technology
          </h1>
          <a className="header-button-text" href="#team">
            Meet the team
          </a>
        </div>
      </div>

      <div className="container body-container">
        <div className="body-positioner" id="faq"></div>
        <div className="body-container-one">
          <p className="body-text">
            Coetic X is a software development company interested in scaling up, growing the network, connecting with teams around the world and assisting them
            with high quality code. A team of top developers that are highly efficient, have a progressive mentality and an immense passion for programming.
          </p>
          {/* <div className="img-body-container">
            <div className="img-body-one"></div>
            <div className="img-body-two"></div>
          </div> */}
        </div>
      </div>

      <div className="clients-home" id="team">
        <div className="container">
          <div>
            <p className="clients-home-title">Our Team</p>
          </div>

          {teamMembers.map((item, index) => {
            if(!item?.url) {
              return (
                <>
                  <div className="team-members-box" style={{ paddingTop: 15, paddingBottom: 15 }}>
                    <div className="team-members-box">
                      <img style={{ width: 120, height: 120, borderRadius: '50%', objectFit: 'cover' }} src={item?.photo} alt={item.fullName} />
                    </div>
                    <div className="team-members-box-content">
                      <span className="team-members-box-location">
                        <span>{item?.position}</span>
                      </span>
                      <h3>{item?.fullName}</h3>
                      <p>{item?.description}</p>
                    </div>
                  </div>
                  {index !== teamMembers.length - 1 && <hr />}
                </>
              );
            }
            return (
              <>
                <a href={item.url} target="_blank" className="team-members-box" style={{ paddingTop: 15, paddingBottom: 15 }}>
                  <div className="team-members-box">
                    <img style={{ width: 120, height: 120, borderRadius: '50%', objectFit: 'cover' }} src={item?.photo} alt={item.fullName} />
                  </div>
                  <div className="team-members-box-content">
                    <span className="team-members-box-location">
                      <span>{item?.position}</span>
                    </span>
                    <h3>{item?.fullName}</h3>
                    <p>{item?.description}</p>
                  </div>
                </a>
                {index !== teamMembers.length - 1 && <hr />}
              </>
            );
          })}
        </div>
      </div>

      {/* <div className="body-container-two">
        <div className='container'>
          <div className="second-body-container-two">

            <div className='team-members-title-container-2'>
              <p className="meet-team-text">Meet the team</p>
              <h1 className="home-grown-title">Unearth Our Worldwide Vision</h1>
            </div>

            <div className="team-members-container">
              {teamMembers.map((item, index) => {
                return (
                  <div className="team-member-container">
                    {item.photo ? (
                      <Link to={item.url} onClick={() => ScrollToTop()}>
                        <img className="team-member-photo" src={item?.photo} alt={item.fullName} />
                      </Link>
                    ) : (
                      <div className="team-member-photo"></div>
                    )}
                    <div>
                      <p className="name-text">{item?.fullName}</p>
                      <p className="position-text">{item?.position}</p>
                      <p className='description'>{item?.description}</p>
                    </div>
                  </div>
                )
              })} */}

      {/* <div className="team-member-photo team-member-last-item">
                <div className="svg-container">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="100"
                    height="100"
                    viewBox="0 0 97 97"
                  >
                    <defs>
                      <clipPath id="clip-path">
                        <path
                          id="Path_1"
                          data-name="Path 1"
                          d="M84.27,539.289v4.735l4.1,2.367,4.1,2.367,4.1,2.367v-4.734l-4.1-2.367-4.1-2.367-4.1-2.367,4.1-2.367v-4.735l-4.1,2.367-4.1,2.367-4.1,2.367v-4.735l-4.1-2.367-4.1-2.367-4.1-2.367v4.734l4.1,2.367,4.1,2.367,4.1,2.367-4.1,2.367v4.735l4.1-2.367,4.1-2.367Zm4.1,11.836-4.1,2.367-4.1,2.367-4.1-2.367-4.1-2.368-4.1-2.367v-9.469l-4.1-2.367v14.2l4.1,2.368,4.1,2.367,4.1,2.367,4.1,2.367,4.1-2.367,4.1-2.367,4.1-2.367Zm4.1-21.305v4.734l4.1-2.367v-4.734l-4.1-2.368-4.1-2.367-4.1-2.367-4.1-2.367-4.1,2.367-4.1,2.367-4.1,2.367,4.1,2.368,4.1-2.368,4.1-2.367,4.1,2.367,4.1,2.368Z"
                          transform="translate(-63.769 -517.984)"
                          fill="none"
                          clip-rule="evenodd"
                        />
                      </clipPath>
                      <linearGradient
                        id="linear-gradient"
                        x1="0.096"
                        y1="0.176"
                        x2="0.904"
                        y2="0.824"
                        gradientUnits="objectBoundingBox"
                      >
                        <stop offset="0" stop-color="#085cdc" />
                        <stop offset="1" stop-color="#28a865" />
                      </linearGradient>
                    </defs>
                    <g
                      id="Group_45"
                      data-name="Group 45"
                      transform="translate(-793 -3745.378)"
                    >
                      <circle
                        id="Ellipse_2"
                        data-name="Ellipse 2"
                        cx="48.5"
                        cy="48.5"
                        r="48.5"
                        transform="translate(793 3745.378)"
                        fill="#eaf2fa"
                      />
                      <g
                        id="Group_44"
                        data-name="Group 44"
                        transform="translate(825.078 3772.495)"
                      >
                        <g
                          id="Group_1"
                          data-name="Group 1"
                          clip-path="url(#clip-path)"
                        >
                          <rect
                            id="Rectangle_1"
                            data-name="Rectangle 1"
                            width="32.802"
                            height="42.611"
                            transform="translate(0 0)"
                            fill="url(#linear-gradient)"
                          />
                        </g>
                      </g>
                    </g>
                  </svg>
                </div>

                <p className="interested-text">Interested in joining the team?</p>

                <a href='/#' className="explore-link-text">Explore open roles</a>
              </div> */}
      {/* </div>
          </div>
        </div>

      </div> */}

      <div className="container">
        <div className="body-container-one">
          <p className="body-text">
            We, as a team, collectively bring a wealth of experience across multiple industries, with a specialization in JS technologies. Our expertise spans
            diverse sectors including fintech, healthcare, logistics, e-commerce, and aviation. We are passionately committed to delivering high-quality work
            and maintaining top-notch efficiency in all that we do.
            {/* <br /><br />
            Our multidisciplinary background equips us to tackle a wide range of challenges and projects, making us your versatile partner for success. As a cohesive unit, we thrive on exceeding expectations and are driven by our dedication to excellence in every endeavor we undertake. */}
          </p>
        </div>
      </div>
    </div>
  );
};
export default AboutPage;
