export const interestedButtons = [
  { id: 'button_site', type: 'button', className: 'button-services', value: 'Site from scratch' },
  { id: 'react_app', type: 'button', className: 'button-services', value: 'App from scratch' },
  { id: 'react_design', type: 'button', className: 'button-services', value: 'UX/UI Design' },
  { id: 'button_brand', type: 'button', className: 'button-services', value: 'Branding' },
  { id: 'web_develop', type: 'button', className: 'button-services', value: 'Development' },
  { id: 'web_maintain', type: 'button', className: 'button-services', value: 'Maintenance' },
];

export const servicesInfo = [
  { id: 'name', name: 'name', type: 'text', className: 'services-placeholders', placeholder: 'Your name' },
  { id: 'email', name: 'email', type: 'email', className: 'services-placeholders', placeholder: 'Your email' },
  { id: 'about', name: 'about', type: 'text', className: 'services-placeholders', placeholder: 'Tell us about your project' },
];

export const servicesBudget = [
  { id: 'button_btn', type: 'button', className: 'budget-amount', value: '10-20k' },
  { id: 'button_services', type: 'button', className: 'budget-amount', value: '30-40k' },
  { id: 'button_budget', type: 'button', className: 'budget-amount', value: '40-50k' },
  { id: 'button_info', type: 'button', className: 'budget-amount', value: '50-100k' },
  { id: 'button_amount', type: 'button', className: 'budget-amount', value: '>100k' },
];